<template>
    <div>
        <main-layout>
            <template v-slot:header>Order details</template>
            <div v-if="order != null">
                <div class="row">
                    <div class="col-12 layout-spacing">
                        <div class="widget">
                            <h5 class="widget-heading">
                                Order Details
                            </h5>
                            <div class="widget-content">
                                <p>
                                    Order {{ id }} <button class="btn btn-sm btn-outline-primary" data-toggle="collapse" data-target="#timelineView">View detailed timeline</button>
                                </p>
                                <p>
                                    Status: <span class="badge badge-pill" :class="`badge-${orderStatusesColorMappings[order.status] ?? 'info'}`">{{ order.status }}</span>
                                </p>
                                <p>
                                    Created: {{ new Date(order.created_at).toDateString() }}
                                </p>
                                <div class="mt-container mx-auto collapse" id="timelineView">
                                    <div class="timeline-line">
                                        <div class="item-timeline" v-for="t in order.tracks" :key="new Date(t.created_at).valueOf()">
                                            <!-- <p class="t-time">10:00</p> -->
                                            <div class="t-dot t-dot-primary">
                                            </div>
                                            <div class="t-text">
                                                <p>{{ t.status.toUpperCase() }}</p>
                                                <p class="t-meta-time">{{ dayjs(t.created_at).format("DD-MM-YYYY") }}</p>
                                            </div>
                                        </div>

                                        <!-- <div class="item-timeline">
                                            <p class="t-time">10:00</p>
                                            <div class="t-dot t-dot-primary">
                                            </div>
                                            <div class="t-text">
                                                <p>Order placed by sender</p>
                                                <p class="t-meta-time">25 mins ago</p>
                                            </div>
                                        </div>-->
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 layout-spacing">
                        <div class="widget">
                            <h5 class="widget-heading">Pickup Information</h5>
                            <div class="widget-content">
                                <p>{{ order.pickup_name }}</p>
                                <p>{{ order.pickup_phone }}</p>
                                <p>someone@example.com</p>
                                <p>{{ order.pickup_address }}</p>
                                <p>Note: {{ order.pickup_extra_information }}</p>
                            </div>
                        </div>
                        <div class="widget mt-3">
                            <h5 class="widget-heading">Delivery Information</h5>
                            <div class="widget-content">
                                <p>{{ order.delivery_name }}</p>
                                <p>{{ order.delivery_phone }}</p>
                                <p>someone@example.com</p>
                                <p>{{ order.delivery_address }}</p>
                                <p>Note: {{order.delivery_extra_information }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 layout-spacing">
                        <div class="widget">
                            <h5 class="widget-heading">Order Items</h5>
                            <div class="widget-content">
                                <h6>{{ order.item_description }}</h6>
                                <p>Delivery cost: {{ formatNumberToCurrency(order.delivery_cost) }}</p>
                            </div>
                        </div>
                        <div class="widget mt-3" v-if="order.status == 'unpaid'">
                            <h5 class="widget-heading">Payments</h5>
                            <div class="widget-content">
                                How do you want to pay?
                                <div class="n-chk">
                                    <label class="new-control new-radio radio-warning">
                                        <input type="radio" name="payment-option" class="new-control-input" value="card" v-model="paymentOption">
                                        <span class="new-control-indicator"></span> Card
                                    </label>
                                    <br>
                                    <label class="new-control new-radio radio-warning">
                                        <input type="radio" name="payment-option" class="new-control-input" value="wallet" v-model="paymentOption">
                                        <span class="new-control-indicator"></span> Wallet balance ({{ formatNumberToCurrency(walletBalance) }})
                                    </label>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-outline-warning" @click="payForOrder">Make payment</button>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="widget mt-3">
                            <h5 class="widget-heading">Attachments</h5>
                            <div class="widget-content">
                                <div class="float-right">
                                    <a href="javascript:void(0)">Upload</a>
                                </div>
                                <p>
                                    <a href="javascript:void(0)" class="text-primary">Download invoice</a>
                                </p>
                                <p>
                                    <a href="javascript:void(0)" class="text-primary">Download invoice</a>
                                </p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </main-layout>
    </div>
</template>
<script>
import dayjs from 'dayjs';
import { useToast } from 'vue-toastification';
import MainLayout from '../../layouts/MainLayout.vue'
import instance from '../../presenter/api/network'
import Auth from '../../helpers/auth';
import appconfig from '../../appconfig';
import { loadScripts } from '../../utils';
export default {
    components: { MainLayout },
    props: ["id"],
    data(){
        return {
            order: null,
            error: "",
            dayjs: dayjs,
            walletBalance: 0,
            orderStatusesColorMappings: {
                pending: "secondary",
                processing: "info",
                "in-transit": "primary",
                completed: "success",
                canceled: "danger"
            },
            paymentOption: "wallet",
            paymentGateway: "paystack"
        }
    },
    methods: {
        getOrder(){
            this.$root.startRequestLoader();
            instance.get("customer/order?orderID="+this.id).then(res => {
                this.order = res.data.data;
            }).catch(err => {
                this.error = true;
                useToast().error("There was an error fetching this order");
            }).finally(() => {
                this.$root.endRequestLoader();
            })
        },
        getBalance(){
            instance.get("customer/wallet-balance", {params: {user_id: Auth.user().id}}).then(res => {
                this.walletBalance = res.data.data;
            }).catch(err => {
                console.log("Can't get balance at time, please refresh page");
            }).finally(() => {
            })
        },
        payForOrder(){
            if (this.paymentOption == "wallet"){
                this.payWithWallet();
            }else if(this.paymentOption == "card"){
                this.payWithCard();
            }
        },
        async payWithCard(){
            this.$root.startRequestLoader();
            let topupAmount = this.order.delivery_cost;
            try{
                let fundRequestData = {amount: topupAmount}
                let fundRequestResponse = await instance.post("customer/fund-wallet", fundRequestData);
                
                this.$root.endRequestLoader();
                let that = this;
                var handler = PaystackPop.setup({
                    key: appconfig.paystackPublicKey,
                    email: Auth.user().email,
                    amount: parseInt(topupAmount) * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit
                    currency: 'NGN', // Use GHS for Ghana Cedis or USD for US Dollars
                    async callback (response){
                    //this happens after the payment is completed successfully
                        that.$root.startRequestLoader();
                        var reference = response.reference;
                        try {
                            const confirmationData = {
                                transactionID: fundRequestResponse.data.data.transactionID,
                                payment_reference: reference,
                                payment_gateway: that.paymentGateway
                            }
                            const confirmFund = await instance.post("customer/confirm-fund-wallet", confirmationData);

                            that.payWithWallet();
                        } catch (error) {
                            that.$root.endRequestLoader();
                            useToast().error("There was an error making payment");
                        }
                    },
                    onClose: function() {
                        alert('Transaction was not completed, window closed.');
                    },
                });
                handler.openIframe();
            }catch(error){
                console.error(error)
                this.$root.endRequestLoader();
                useToast().error("You are unable to pay at this time, please try again later");
            }
        },
        payWithWallet(){
            this.$root.startRequestLoader();
            instance.post("customer/pay-order", {orderID: this.id}).then(res => {
                useToast().success("Order paid for successfully. This page will automatically refresh in a bit");
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }).catch(err => {
                if (err.response){
                    useToast().error(err.response.data?.message ?? "There was an error paying for your order, please try again")
                }else{
                    useToast().error("An unknown error occured");
                }
            }).finally(() => {
                this.$root.endRequestLoader();
            })
        }
    },
    mounted(){
        this.getOrder();
        this.getBalance();
        loadScripts([
            "https://js.paystack.co/v1/inline.js"
        ])
    }
    
}
</script>
<style scoped>
@import url("../../assets/lib/vendor/css/components/timeline/custom-timeline.css");
@import url("../../assets/lib/vendor/css/forms/theme-checkbox-radio.css");
</style>